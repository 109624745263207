<template>
  <header class="w-100 p-0">
    <b-navbar class="w-100 bg-dagee-blue align-items-center py-0 headerBar"
      :class="{'active':headerShow}" style="height: 40px; max-height:40px" toggleable>
      <b-navbar-brand class="order-0" :to="{name: 'index'}">{{lang.LogoText}}</b-navbar-brand>
      <div class="d-flex align-items-center">
        <div class="btn btn-sm mr-2" v-if="showSearch">
          <b-icon icon="search" @click="openSearch = !openSearch"></b-icon>
        </div>

        <div @click="openLogin()" v-if="member.state">
          <font-awesome-icon class="text-dark" :icon="['fas', 'user']" size='lg' />
        </div>

        <router-link class="d-flex align-items-center cur-print" :to='{name:"login"}' tag="a"
          v-if="!member.state">
          <font-awesome-icon :icon="['fas', 'user']" size='lg' />
        </router-link>
      </div>
    </b-navbar>

    <div class="w-100 position-absolute text-left p-2 overflow-auto showPosition"
      style="z-index:1030; background-color: rgba(255,255,255,0.7)" :class="{'active': smopen}"
      v-if="showSearch && openSearch">
      <div class="d-inline-flex align-items-center justify-content-center">
        <div class="pms-typesearch-item" v-for='(item, index) in quittype' :key="index"
          :class='{"active": item.name==quitdata.method}' @click='selectedquittype(item.name)'>
          <a href="#point" class="font-up-0 text-nowrap" @click.prevent>
            <font-awesome-icon class="mr-md-2" :icon="['fas', item.icon]" size="lg" />
            {{searchLang.text[item.name]}}
          </a>
        </div>
      </div>

      <div class="d-flex align-items-center justify-content-center"
        style="max-width: 100%; min-width:300px; width: 460px">
        <search-btn v-model="quitdata.searchtext" :search='SearchData'>
          <template class="w-100" #searchbtn='{searchbtn}'>
            <b-button-group size="sm" class='b-button-checked mr-0 '>
              <b-button class='b-button-item text-nowrap'
                :class="{'active':quitdata.searchtype=='Preferences'}"
                @click='searchbtn("Preferences")'>
                <span class="px-2">{{searchLang.text.Preferences}}</span>
              </b-button>
              <b-button class='b-button-item' :class="{'active':quitdata.searchtype=='google'}"
                v-if="getLevel" @click='searchbtn("google")'>
                <span class="px-2">{{searchLang.text.GoogleSearch}}</span>
              </b-button>
            </b-button-group>
          </template>
        </search-btn>
      </div>
    </div>
  </header>
</template>

<script>
  import store from '@/store';
  import sup from "@/service/support";
  import searchBtn from "@/components/Input/Searchbtn";
  export default {
    name: 'home',

    data() {
      return {
        quitdata: {
          method: "point",
          searchtext: "",
          searchtype: "Preferences"
        },
        lat: 25.0329693999999989273419487290084362030029296875,
        lng: 121.5654176999999975805621943436563014984130859375,
      };
    },

    computed: {
      getLevel: {
        get() {
          return this.$store.getters["user/getLevel"];
        }
      },
      googldSearch: {
        get() {
          const field = ["MEM20072900010", "MEM20073000020", "MEM20081400040", "MEM20081400030",
            "MEM20073000010"
          ];
          const uid = this.$store.state.user.Login.uid;
          return (_.indexOf(field, uid) > -1 ? true : false);
        }
      },

      headerShow: {
        get() {
          return this.$store.state.Public.headerShow;
        },
      },

      showSearch: {
        get() {
          return this.$store.state.Public.searchShow;
        },

      },

      openSearch: {
        get() {
          return this.$store.state.Public.openSearch;
        },
        set(show) {
          this.$store.commit("Public/setOpenSearch", show);
        }
      },
      lang: {
        get() {
          return store.state.Public.lang.public;
        },
      },

      searchLang: {
        get() {
          return store.state.Public.lang.schedule;
        }
      },

      member: {
        get() {
          return store.state.user.Login;
        }
      },

      quittype: {
        get() {
          return store.state.schedule.quittype;
        }
      },

      mapdata: {
        get() {
          return this.$store.state.map.mapData;
        }
      },

      smopen: {
        get() {
          return this.$store.state.Public.smopen;
        },
      }
    },

    components: {
      searchBtn
    },

    methods: {
      openLogin() {
        this.$store.dispatch("user/openLogin", true);
      },

      selectedquittype(text) {
        this.quitdata.method = text;
      },

      SearchData(searchtype = "") {
        this.quitdata.searchtype = (searchtype) ? searchtype : this.quitdata.searchtype;
        const quitdata = this.quitdata;
        // console.log(this.mapdata.mapcenter[0], this.mapdata.zoom);
        const scale = sup.zoom_scale(this.mapdata.mapcenter[0], this.mapdata.zoom, document
          .querySelector("#osmmap").clientWidth);
        // console.log(scale);
        // return ;
        const response = {
          method: quitdata.method,
          query: {
            lat: this.mapdata.mapcenter[0],
            lng: this.mapdata.mapcenter[1],
            searchmethod: quitdata.method,
            scale: scale,
            searchtext: this.quitdata.searchtext
          },
          searchtype: quitdata.searchtype,
          pagetoken: null
        };
        // console.log(response);
        // return;

        this.$store.commit("schedule/setSearchRequest", response);

        this.SearchPlace = "";

        store.dispatch("schedule/SearchData", response);
        // this.openSearch = false;
      },
    }
  };

</script>
